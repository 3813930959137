import {
  ITextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import {History, Location} from 'history';
import React, {RefObject} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {Banner, BannerProps} from '../common/banner';
import {Logo} from '../common/Logo';
import {bodyColor} from '../styles';

type Props = {
  location: Location;
  history: History;
};

type State = {
  username: string;
  password: string;
  error: string;
  banner?: BannerProps;
};

export function SignIn(): JSX.Element | null {
  const location = useLocation();
  const history = useHistory();
  return <SignInComponent location={location} history={history} />;
}

class SignInComponent extends React.Component<Props, State> {
  private readonly password: RefObject<ITextField>;
  private readonly ctx: ApiContext;

  constructor(props: Props) {
    super(props);

    this.password = React.createRef();

    this.state = {
      username: '',
      password: '',
      error: '',
    };

    this.ctx = api.newContext();
  }

  async componentDidMount() {
    try {
      const resp = await api.getJson(this.ctx, '/signin');

      if (resp.banner) {
        this.setState({banner: resp.banner});
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    this.ctx.abort();
  }

  onSignIn = async () => {
    try {
      await api.signIn(this.ctx, this.state.username, this.state.password);
      const params = new URLSearchParams(this.props.location.search);
      const path = params.has('to') ? params.get('to') || '/' : '/';
      window.location.replace(path);
    } catch (err) {
      if (typeof err === 'string') {
        this.setState({error: err});
      }
    }
  };

  renderBanner() {
    if (!this.state.banner) {
      return <Spacer />;
    }

    return (
      <BannerContainer>
        <Banner {...this.state.banner} />
      </BannerContainer>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <MessageBarContainer>
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
          {this.state.error}
        </MessageBar>
      </MessageBarContainer>
    );
  }

  renderForm() {
    return (
      <FormWrapper>
        <form>
          <Field
            placeholder={'ユーザー名'}
            onChange={(_, v) => {
              this.setState({username: v || ''});
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.password.current!.focus();
              }
            }}
            value={this.state.username}
            autoComplete={'off'}
            autoCapitalize={'off'}
            autoCorrect={'off'}
            name={'username'}
          />
          <Field
            type={'password'}
            placeholder={'パスワード'}
            componentRef={this.password}
            onChange={(_, v) => {
              this.setState({password: v || ''});
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.onSignIn();
              }
            }}
            value={this.state.password}
            autoComplete={'current-password'}
            name={'password'}
          />
          <PrimaryButton
            text={'ログイン'}
            onClick={this.onSignIn}
            styles={{root: {marginTop: '1rem', width: '100%'}}}
          />
        </form>
      </FormWrapper>
    );
  }

  render() {
    return (
      <Wrapper>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {this.renderBanner()}
        {this.renderError()}
        {this.renderForm()}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: ${bodyColor};
`;

const BannerContainer = styled.div`
  text-align: initial;
  min-width: 300px;
  max-width: min(80vw, 600px);
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const Spacer = styled.div`
  height: 5rem;
`;

const FormWrapper = styled.div`
  & input {
    display: block;
  }
`;

const Field = styled(TextField)`
  margin-top: 1rem;
  width: 300px;
`;

const LogoContainer = styled.div``;

const MessageBarContainer = styled.div`
  font-size: 14px;
  width: 300px;
`;
