import {Component} from '../types/Schema';
import {Sorter} from '../types/Sorter';
import {GridListProps} from './GridList';
import {SimpleListProps} from './SimpleList';

export function selectComponent<T>(
  components: {[area: string]: Component},
  type: string,
): T | null {
  const area = selectArea(components, type);

  if (!area) {
    return null;
  }

  return components[area] as unknown as T;
}

export function selectArea(
  components: {[area: string]: Component},
  type: string,
): string | undefined {
  return Object.keys(components).find((area) => components[area].type === type);
}

export function selectAreaAndComponent<T>(
  components: {[area: string]: Component},
  type: string,
): {
  area: string | undefined;
  component: T | null;
} {
  const area = selectArea(components, type);
  const component = selectComponent<T>(components, type);

  return {
    area,
    component,
  };
}

export type OnSelectRowProps = {
  type: string;
  url: string;
  appId?: string;
  urlFieldId?: string;
  paramsFieldId?: string;
};

type ListComponent = {
  sorters?: Sorter[];
  onSelectRow?: OnSelectRowProps;
};

export function selectListComponent(components: {
  [area: string]: Component;
}): ListComponent | null {
  return (
    selectComponent<SimpleListProps>(components, 'simple-list') ||
    selectComponent<GridListProps>(components, 'grid-list') ||
    selectComponent<GridListProps>(components, 'row-calendar-list')
  );
}
