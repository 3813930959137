import {
  ComboBox,
  DefaultButton,
  IComboBoxOption,
  PrimaryButton,
} from '@fluentui/react';
import React, {CSSProperties, useCallback, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {isMobile} from '../common/device';
import {Dialog} from '../common/Dialog';
import {getDateText, getTimeText} from '../dates/dates';
import {ResourceDetails} from '../types/ResourceDetails';
import {CalendarWidget} from './CalendarWidget';
import {ClearButton} from './common/ClearButton';
import {DateSelector} from './common/DateSelector';
import {formatDate, formatDateUTC, toDate} from './common/dateutil';
import {useTimeRange} from './TimeRangeWidget';

type InputProps = {
  value: any;
  onChange: (v: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  style?: CSSProperties;
};

export function TimeInputWidget(props: InputProps): JSX.Element | null {
  const date = newDate(props.value);
  const dateStr = getDateText(date) || getDateText(new Date());
  const timeStr = getTimeText(date) || '00:00';
  const onChange = props.onChange;

  const onChangeDate = useCallback(
    (date?: Date | null) => {
      const str = getDateText(date);
      const d = new Date(str + 'T' + timeStr);
      onChange(formatDateUTC(d));
    },
    [onChange, timeStr],
  );

  const onChangeTime = useCallback(
    (_: any, opt?: IComboBoxOption, index?: number, value?: string) => {
      if (opt) {
        const d = new Date(dateStr + 'T' + opt.text);
        onChange(formatDateUTC(d));
        return;
      }

      if (value) {
        const d = new Date(dateStr + 'T' + formatTimeOrDefault(value, timeStr));
        onChange(formatDateUTC(d));
        return;
      }

      onChange('');
    },
    [onChange, dateStr, timeStr],
  );

  return (
    <Container style={props.style}>
      <DateContainer>
        <DateSelector
          value={toDate(props.value)}
          onSelectDate={onChangeDate}
          placeholder={props.placeholder}
        />
      </DateContainer>
      <TimeContainer>
        <ComboBox
          allowFreeform={true}
          autoComplete={'on'}
          options={TIMES}
          styles={{
            optionsContainer: {
              width: '80px',
              maxHeight: '300px',
            },
          }}
          text={getTimeText(date)}
          onChange={onChangeTime}
        />
      </TimeContainer>
      <ClearButton
        onClick={() => {
          onChange('');
        }}
        styles={{root: {marginLeft: '0.5rem'}}}
      />
    </Container>
  );
}

type RangeInputProps = {
  placeholder?: string;
  readOnly?: boolean;
  calendar?: boolean;
  style?: CSSProperties;
  startId: string;
  endId: string;
  startValue: string;
  endValue: string;
  onChangeStart: (v: string) => void;
  onChangeEnd: (v: string) => void;
  onChangeRange: (start: string, end: string) => void;
  onListEvents: () => Promise<ResourceDetails[]>;
};

export function TimeRangeInputWidget(
  props: RangeInputProps,
): JSX.Element | null {
  return (
    <Container>
      <div>
        <TimeInputWidget
          {...props}
          style={{marginBottom: '0.5rem'}}
          value={props.startValue}
          onChange={props.onChangeStart}
        />
        <TimeInputWidget
          {...props}
          value={props.endValue}
          onChange={props.onChangeEnd}
        />
      </div>
      {renderCalendarButton(props)}
    </Container>
  );
}

type OutputProps = {
  value: any;
  style?: CSSProperties;
};

export function TimeOutputWidget(props: OutputProps): JSX.Element | null {
  const date = newDate(props.value);
  const d = formatDate(date);
  const t = getTimeText(date);

  return (
    <Container title={`${d} ${t}`} style={props.style}>
      <DateTimeText>{d}</DateTimeText>
      <DateTimeText>{t}</DateTimeText>
    </Container>
  );
}

function newDate(v: any): Date | null {
  if (!v) {
    return null;
  }

  return new Date(v);
}

function formatTimeOrDefault(v: string, def: string): string {
  if (/^\d{1,2}:\d{1,2}$/.test(v)) {
    return v;
  }

  return def;
}

function renderCalendarButton(props: RangeInputProps) {
  if (isMobile()) {
    // Calendar widgets are not supported on mobile at this time.
    return null;
  }

  if (!props.calendar) {
    return null;
  }

  return <CalendarButton {...props} />;
}

function CalendarButton(props: RangeInputProps): JSX.Element | null {
  const intl = useIntl();
  const dialog = useRef<Dialog>(null);
  const [start, end, setTimeRange] = useTimeRange();
  const onChange = props.onChangeRange;

  const onSelectTimeRange = useCallback(async () => {
    const s = formatDateUTC(start);
    const e = formatDateUTC(end);
    await onChange(s, e);
    dialog.current?.closeDialog();
  }, [start, end, onChange]);

  const [events, setEvents] = useState<ResourceDetails[]>([]);
  const onListEvents = props.onListEvents;

  return (
    <>
      <DefaultButton
        text={intl.formatMessage({id: 'Action.OpenSelector'})}
        onClick={() => {
          setTimeout(async () => {
            try {
              const list = await onListEvents();
              setEvents(list);
            } catch (e) {
              console.error(e);
            }
          }, 33);
          dialog.current?.showDialog();
        }}
        styles={{
          root: {
            marginLeft: '0.5rem',
            whiteSpace: 'nowrap',
          },
        }}
      />
      <Dialog ref={dialog} maxWidth="100%">
        <CalendarContainer>
          <CalendarWidget
            {...props}
            onSelectTimeRange={setTimeRange}
            events={events}
            value={{start, end}}
            startAccessor={props.startId}
            endAccessor={props.endId}
          />
          <Buttons>
            <PrimaryButton
              text={intl.formatMessage({id: 'Action.Decide'})}
              onClick={onSelectTimeRange}
              styles={{
                root: {
                  marginRight: '1rem',
                  whiteSpace: 'nowrap',
                },
              }}
            />
            <DefaultButton
              text={intl.formatMessage({id: 'Action.Cancel'})}
              onClick={() => {
                dialog.current?.closeDialog();
              }}
              styles={{
                root: {
                  whiteSpace: 'nowrap',
                },
              }}
            />
          </Buttons>
        </CalendarContainer>
      </Dialog>
    </>
  );
}

const TIMES = [
  {key: '00:00', text: '00:00'},
  {key: '00:30', text: '00:30'},
  {key: '01:00', text: '01:00'},
  {key: '01:30', text: '01:30'},
  {key: '02:00', text: '02:00'},
  {key: '02:30', text: '02:30'},
  {key: '03:00', text: '03:00'},
  {key: '03:30', text: '03:30'},
  {key: '04:00', text: '04:00'},
  {key: '04:30', text: '04:30'},
  {key: '05:00', text: '05:00'},
  {key: '05:30', text: '05:30'},
  {key: '06:00', text: '06:00'},
  {key: '06:30', text: '06:30'},
  {key: '07:00', text: '07:00'},
  {key: '07:30', text: '07:30'},
  {key: '08:00', text: '08:00'},
  {key: '08:30', text: '08:30'},
  {key: '09:00', text: '09:00'},
  {key: '09:30', text: '09:30'},
  {key: '10:00', text: '10:00'},
  {key: '10:30', text: '10:30'},
  {key: '11:00', text: '11:00'},
  {key: '11:30', text: '11:30'},
  {key: '12:00', text: '12:00'},
  {key: '12:30', text: '12:30'},
  {key: '13:00', text: '13:00'},
  {key: '13:30', text: '13:30'},
  {key: '14:00', text: '14:00'},
  {key: '14:30', text: '14:30'},
  {key: '15:00', text: '15:00'},
  {key: '15:30', text: '15:30'},
  {key: '16:00', text: '16:00'},
  {key: '16:30', text: '16:30'},
  {key: '17:00', text: '17:00'},
  {key: '17:30', text: '17:30'},
  {key: '18:00', text: '18:00'},
  {key: '18:30', text: '18:30'},
  {key: '19:00', text: '19:00'},
  {key: '19:30', text: '19:30'},
  {key: '20:00', text: '20:00'},
  {key: '20:30', text: '20:30'},
  {key: '21:00', text: '21:00'},
  {key: '21:30', text: '21:30'},
  {key: '22:00', text: '22:00'},
  {key: '22:30', text: '22:30'},
  {key: '23:00', text: '23:00'},
  {key: '23:30', text: '23:30'},
];

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const DateTimeText = styled.div`
  font-size: 1rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
`;

const DateContainer = styled.div`
  max-width: 300px;
  margin-right: 0.5rem;
`;

const TimeContainer = styled.div`
  width: 100px;
  flex-shrink: 0;
`;

const Buttons = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CalendarContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;
