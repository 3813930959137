import React, {useState} from 'react';

import {IntlProvider} from 'react-intl';
import {useMediaQuery} from 'react-responsive';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import './App.css';
import {Footer} from './common/Footer';
import {MobileNav} from './common/mobile/MobileNav';
import {Nav} from './common/Nav';
import {Protected} from './common/Protected';
import {NavContext, NavProps} from './context';
import {enableCustomIcons} from './icons/icons';
import {ContentOnlyLayout} from './layout/ContentOnlyLayout';

import {MainLayout} from './layout/MainLayout';
import {MobileLayout} from './layout/MobileLayout';
import {messages} from './messages';
import {AclScreen} from './pages/AclScreen';
import {ConfigScreen} from './pages/ConfigScreen';
import {EditItemScreen} from './pages/EditItemScreen';
import {Home} from './pages/Home';
import {ItemScreen} from './pages/ItemScreen';
import {ListScreenContainer} from './pages/ListScreen';
import {MobileHome} from './pages/mobile/MobileHome';
import {MobileItemScreen} from './pages/mobile/MobileItemScreen';
import {MobileListScreenContainer} from './pages/mobile/MobileListScreen';
import {NewItemScreen} from './pages/NewItemScreen';
import {Password} from './pages/Password';
import {SignIn} from './pages/SignIn';
import {SystemConfigScreen} from './pages/SystemConfigScreen';
import {isContentOnly} from './util';
import './viewport-size';

const Content = styled.div`
  height: 100%;
`;

enableCustomIcons();

function App(): JSX.Element | null {
  const [nav, setNav] = useState<NavProps | undefined>();

  const lang = 'ja';

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <NavContext.Provider value={{nav, setNav}}>
        <Contents />
      </NavContext.Provider>
    </IntlProvider>
  );
}

const Contents = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)',
  });

  if (isContentOnly()) {
    return <ContentOnly />;
  }

  if (isDesktopOrLaptop) {
    return <Desktop />;
  }

  return <Mobile />;
};

const MainContent = () => {
  return (
    <Content>
      <Switch>
        <Route exact path="/signin">
          <SignIn />
        </Route>
        <Protected fallback="/signin">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/password">
              <Password />
            </Route>
            <Route exact path="/sys/config">
              <SystemConfigScreen />
            </Route>
            <Route exact path="/app/:appId">
              <ListScreenContainer />
            </Route>
            <Route exact path="/app/:appId/config">
              <ConfigScreen />
            </Route>
            <Route exact path="/app/:appId/new">
              <NewItemScreen />
            </Route>
            <Route exact path="/app/:appId/proxy/:proxyKey">
              <ItemScreen />
            </Route>
            <Route exact path="/app/:appId/:resourceId">
              <ItemScreen />
            </Route>
            <Route exact path="/app/:appId/:resourceId/edit">
              <EditItemScreen />
            </Route>
            <Route exact path="/app/:appId/:resourceId/acl">
              <AclScreen />
            </Route>
          </Switch>
        </Protected>
      </Switch>
    </Content>
  );
};

const ContentOnly = () => {
  return (
    <BrowserRouter>
      <ContentOnlyLayout.Container>
        <ContentOnlyLayout.Content>
          <MainContent />
        </ContentOnlyLayout.Content>
      </ContentOnlyLayout.Container>
    </BrowserRouter>
  );
};

const Desktop = () => {
  return (
    <BrowserRouter>
      <MainLayout.Container>
        <MainLayout.Header>
          <Switch>
            <Route exact path="/signin" />
            <Protected fallback="/signin">
              <Route>
                <Nav />
              </Route>
            </Protected>
          </Switch>
        </MainLayout.Header>
        <MainLayout.Content>
          <MainContent />
        </MainLayout.Content>
        <MainLayout.Footer>
          <Footer />
        </MainLayout.Footer>
      </MainLayout.Container>
    </BrowserRouter>
  );
};

const Mobile = () => {
  return (
    <BrowserRouter>
      <MobileLayout.Container>
        <MobileLayout.Header>
          <Switch>
            <Route exact path="/signin" />
            <Protected fallback="/signin">
              <Route>
                <MobileNav />
              </Route>
            </Protected>
          </Switch>
        </MobileLayout.Header>
        <MobileLayout.Content>
          <Content>
            <Switch>
              <Route exact path="/signin">
                <SignIn />
              </Route>
              <Protected fallback="/signin">
                <Switch>
                  <Route exact path="/">
                    <MobileHome />
                  </Route>
                  <Route exact path="/password">
                    <Password />
                  </Route>
                  <Route exact path="/app/:appId">
                    <MobileListScreenContainer />
                  </Route>
                  <Route exact path="/app/:appId/config">
                    <ConfigScreen />
                  </Route>
                  <Route exact path="/app/:appId/new">
                    <NewItemScreen />
                  </Route>
                  <Route exact path="/app/:appId/proxy/:proxyKey">
                    <MobileItemScreen />
                  </Route>
                  <Route exact path="/app/:appId/:resourceId">
                    <MobileItemScreen />
                  </Route>
                  <Route exact path="/app/:appId/:resourceId/edit">
                    <EditItemScreen />
                  </Route>
                </Switch>
              </Protected>
            </Switch>
          </Content>
        </MobileLayout.Content>
        <MobileLayout.Footer>
          <Footer />
        </MobileLayout.Footer>
      </MobileLayout.Container>
    </BrowserRouter>
  );
};

export default App;
