import {
  Calendar,
  Callout,
  DateRangeType,
  DayOfWeek,
  DefaultButton,
  DirectionalHint,
  IButtonStyles,
  TextField,
} from '@fluentui/react';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {bodyColor, borderColor, textColor} from '../../styles';
import {calendarStringsJa, toDate} from './dateutil';

type Props = {
  value?: Date;
  onSelectDate: (date: Date | null) => void;
  placeholder?: string;
  isDayPickerVisible: boolean;
  dateFormatter: (date?: Date | null) => string;
};

export function DateSelectorBase(props: Props): JSX.Element {
  const intl = useIntl();
  const selectedDateWithFallback = props.value || new Date();
  const [shown, setShown] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const value = props.value;
  const dateFormatter = props.dateFormatter;

  useEffect(() => {
    const textFromProps = dateFormatter(value);

    if (textFromProps !== text) {
      setText(textFromProps);
    }
  }, [value, dateFormatter, text]);

  const onSelectDate = (date: Date): void => {
    if (changedOnlyDayInSameMonth(date, props.value)) {
      setShown(false);
    }
  };

  const onNavigateDate = (date: Date) => {
    if (isValidDate(date)) {
      props.onSelectDate(date);
      setText(props.dateFormatter(date));
    } else {
      setText(props.dateFormatter(props.value));
    }
  };

  const tryNavigateDate = () => {
    try {
      const date = toDate(text);

      if (date) {
        onNavigateDate(date);
      }
    } catch (ignore) {
      setText('');
    }
  };

  const selectSameDay = {
    navigatedDate: selectedDateWithFallback,
    selectedDate: selectedDateWithFallback,
    onNavigateDate: onNavigateDate,
  };

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <TextContainer
        ref={ref}
        onClick={() => {
          setShown(!shown);
        }}>
        <TextField
          value={text}
          placeholder={props.placeholder}
          onChange={(_, text) => {
            setText(text || '');
          }}
          onBlur={tryNavigateDate}
          styles={textFieldStyles}
          iconProps={iconProps}
        />
      </TextContainer>
      {shown ? (
        <Callout
          target={ref.current}
          isBeakVisible={false}
          gapSpace={0}
          directionalHint={DirectionalHint.bottomLeftEdge}
          doNotLayer={false}
          onDismiss={() => {
            setShown(false);
          }}
          layerProps={layerProps}>
          <Calendar
            onSelectDate={onSelectDate}
            dateRangeType={DateRangeType.Day}
            value={props.value}
            firstDayOfWeek={DayOfWeek.Sunday}
            strings={calendarStringsJa}
            highlightCurrentMonth={true}
            highlightSelectedMonth={true}
            isDayPickerVisible={props.isDayPickerVisible}
            isMonthPickerVisible={true}
            showMonthPickerAsOverlay={false}
            showGoToToday={false}
            showWeekNumbers={false}
            calendarDayProps={selectSameDay}
            calendarMonthProps={selectSameDay}
            styles={{
              root: {
                minHeight: 240,
              },
            }}
          />
          <DefaultButton
            onClick={() => {
              const today = new Date();
              onNavigateDate(today);
              onSelectDate(today);
            }}
            styles={goToTodayButtonStyles}
            text={intl.formatMessage({id: 'Widget.Calendar.Today'})}
          />
        </Callout>
      ) : null}
    </Container>
  );
}

function isValidDate(date?: Date): date is Date {
  if (!date) {
    return false;
  }

  if (Number.isNaN(date.getTime())) {
    return false;
  }

  const year = date.getFullYear();
  return 1000 < year && year < 10000;
}

function changedOnlyDayInSameMonth(newDay: Date, prevDay?: Date): boolean {
  if (!prevDay) {
    return true;
  }

  return (
    prevDay.getFullYear() === newDay.getFullYear() &&
    prevDay.getMonth() === newDay.getMonth()
  );
}

const iconProps = {
  iconName: 'Calendar',
  styles: {
    root: {
      fontSize: 20,
      color: borderColor,
      top: 6,
      right: 4,
    },
  },
};

const layerProps = {
  eventBubblingEnabled: true,
  styles: {
    root: {
      visibility: 'visible',
    },
  },
};

const goToTodayButtonStyles: IButtonStyles = {
  root: {
    fontSize: '0.7rem',
    border: 'none',
    width: '3rem',
    minWidth: '3rem',
    padding: 0,
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  label: {
    fontWeight: 'normal',
  },
};

const textFieldStyles = {
  fieldGroup: {borderColor: borderColor},
  field: {
    backgroundColor: bodyColor,
    color: textColor,
    cursor: 'pointer',
  },
};

const Container = styled.div`
  display: flex;
`;

const TextContainer = styled.div``;
