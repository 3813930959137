import {History} from 'history';
import {useEffect, useMemo} from 'react';
import {ApiError} from './ApiError';
import {newDummyNav} from './common/Nav';
import {getTimeZone, getTimeZoneQuery, NAME_TIME_ZONE} from './timezone';
import {FormFiles} from './types/Form';
import {HomeMenu} from './types/HomeMenu';
import {Resource} from './types/Resource';
import {ResourceList} from './types/ResourceList';
import {Schema, SchemaWithoutScreen} from './types/Schema';
import {User} from './types/User';
import {downloadByPost} from './util';

type Item = string | null;
type Props = {
  history: History;
};

export type onClickWithPropsFn = (props: Props) => void;

export type Query = string | URLSearchParams | Data;

export type Data = {
  [key: string]: any;
};

class ApiStorage {
  private readonly storage: Storage;

  constructor() {
    this.storage = localStorage;
  }

  setItem(key: string, obj: Item) {
    this.storage.setItem(key, JSON.stringify(obj));
  }

  getItem(key: string) {
    const item = this.storage.getItem(key);

    if (item == null) {
      return item;
    }

    return JSON.parse(item);
  }

  clear() {
    this.storage.clear();
  }
}

export class ApiContext {
  private readonly abortController: AbortController;

  constructor() {
    this.abortController = new AbortController();
  }

  abort() {
    this.abortController.abort();
  }

  get signal() {
    return this.abortController.signal;
  }
}

export class Api {
  private readonly baseUrl: string;
  private readonly baseWsUrl: string;
  private readonly storage: ApiStorage;
  private accessToken: string | null;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.baseWsUrl = buildWsURL(baseUrl);
    this.storage = new ApiStorage();
    this.accessToken = this.storage.getItem('access_token');
    this.check = this.check.bind(this);
  }

  newContext(): ApiContext {
    return new ApiContext();
  }

  clear() {
    this.accessToken = null;
    this.storage.clear();
  }

  async ping(ctx: ApiContext): Promise<boolean> {
    try {
      const headers = this.makeHeaders();
      const response = await fetch(this.url('/ping'), {
        method: 'GET',
        headers,
        mode: 'cors',
        signal: ctx.signal,
      });
      return response.status === 200;
    } catch {
      return false;
    }
  }

  signIn(ctx: ApiContext, username: string, password: string) {
    return this.postJson(ctx, '/signin', {username, password})
      .then((x) => {
        this.accessToken = x.access_token;
        this.storage.setItem('access_token', x.access_token);
        this.storage.setItem('current_user', x.current_user);
        return x.current_user;
      })
      .catch((err) => {
        console.error(err);
        if (!err.body || !err.body.errors || !err.body.errors.username) {
          return Promise.reject('ログインに失敗しました');
        }

        return Promise.reject(err.body.errors.username.join('\n'));
      });
  }

  signOut = async (ctx: ApiContext, props: Props) => {
    await this.deleteJson(ctx, '/signout');
    this.clear();
    props.history.push('/signin');
  };

  changePassword(
    ctx: ApiContext,
    currentPassword: string,
    password: string,
    passwordConfirmation: string,
  ) {
    return this.putJson(ctx, '/password', {
      currentPassword,
      password,
      passwordConfirmation,
    }).catch((err) => {
      return Promise.reject(err);
    });
  }

  getCurrentUser(): User {
    return this.storage.getItem('current_user');
  }

  fetchSchema = async (
    ctx: ApiContext,
    appId: string,
    data: Data | URLSearchParams,
  ): Promise<Schema> => {
    const d = buildData(data);
    return this.postJson(ctx, `/app/${appId}/schema`, d);
  };

  fetchRelatedSchemas = async (
    ctx: ApiContext,
    appId: string,
    data?: Data | URLSearchParams,
  ): Promise<SchemaWithoutScreen[]> => {
    const d = buildData(data);
    return this.postJson(ctx, `/app/${appId}/schema/related`, d);
  };

  fetchMeta() {
    return Promise.resolve({
      title: '工具管理',
    });
  }

  fetchTypeMeta(ctx: ApiContext, appId: string) {
    return this.getJson(ctx, '/meta/' + appId).then((x) => x.schema);
  }

  apps = async (ctx: ApiContext) => {
    const user = this.getCurrentUser();

    if (!user) {
      return [];
    }

    return this.getJson(ctx, '/app');
  };

  listHomeMenu(ctx: ApiContext): Promise<HomeMenu> {
    const user = this.getCurrentUser();

    if (!user) {
      return Promise.resolve({nav: newDummyNav(), menu: []});
    }

    return this.getJson(ctx, '/home');
  }

  listMenu(ctx: ApiContext, params?: Query) {
    const user = this.getCurrentUser();

    if (!user) {
      return Promise.resolve([]);
    }

    return this.getJson(ctx, '/menu', params).then((menu) => {
      menu.push({
        name: 'ログアウト',
        onClickWithProps: (props: Props) => {
          this.signOut(ctx, props);
        },
      });

      return menu;
    });
  }

  list(
    ctx: ApiContext,
    appId: string,
    data?: Data | URLSearchParams | string,
  ): Promise<ResourceList> {
    const d = buildData(data);
    return this.postJson(ctx, `/app/${appId}/list`, d);
  }

  listAll(
    ctx: ApiContext,
    appId: string,
    params?: Query,
  ): Promise<ResourceList> {
    return this.getJson(ctx, '/app/' + appId, params);
  }

  find(ctx: ApiContext, appId: string, ids: string[]) {
    if (!ids) {
      return Promise.resolve({});
    }

    return this.listAll(ctx, appId, {id: ids});
  }

  show(
    ctx: ApiContext,
    appId: string,
    id: string,
    query?: Query,
  ): Promise<Resource> {
    return this.getJson(ctx, '/app/' + appId + '/' + id, query);
  }

  loadConfig(ctx: ApiContext, appId: string, query?: Query): Promise<Resource> {
    return this.getJson(ctx, '/app/' + appId + '/config', query);
  }

  new_(ctx: ApiContext, appId: string, params?: Query): Promise<Resource> {
    return this.getJson(ctx, '/app/' + appId + '/new', params);
  }

  newByPost(ctx: ApiContext, appId: string, data: Data): Promise<Resource> {
    return this.postJson(ctx, '/app/' + appId + '/new', data);
  }

  edit(ctx: ApiContext, appId: string, id: string): Promise<Resource> {
    return this.getJson(ctx, '/app/' + appId + '/' + id + '/edit');
  }

  newDelete(
    ctx: ApiContext,
    appId: string,
    id: string,
    params?: Query,
  ): Promise<Resource> {
    return this.getJson(ctx, '/app/' + appId + '/' + id + '/delete', params);
  }

  batchEdit(ctx: ApiContext, appId: string, ids: Set<string>) {
    return this.postJson(ctx, '/app/' + appId + '/batch/new', {ids: [...ids]});
  }

  batchUpdate(ctx: ApiContext, appId: string, ids: Set<string>, data: Data) {
    return this.postJson(ctx, '/app/' + appId + '/batch', {
      ids: [...ids],
      details: data,
    });
  }

  copy(ctx: ApiContext, appId: string, id: string): Promise<Resource> {
    return this.getJson(ctx, '/app/' + appId + '/' + id + '/copy');
  }

  create(
    ctx: ApiContext,
    appId: string,
    data: Data,
    files?: FormFiles,
    relatedData?: any,
  ): Promise<Resource> {
    if (files && Object.keys(files).length > 0) {
      return this.postFormData(
        ctx,
        '/app/' + appId,
        {
          details: data,
          relatedDetails: relatedData,
        },
        files,
      );
    }

    return this.postJson(ctx, '/app/' + appId, {
      details: data,
      relatedDetails: relatedData,
    });
  }

  update(
    ctx: ApiContext,
    appId: string,
    id: string,
    data: Data,
    files?: FormFiles,
    relatedData?: any,
  ): Promise<Resource> {
    if (files && Object.keys(files).length > 0) {
      return this.putFormData(
        ctx,
        '/app/' + appId + '/' + id,
        {
          details: data,
          relatedDetails: relatedData,
        },
        files,
      );
    }

    return this.putJson(ctx, '/app/' + appId + '/' + id, {
      details: data,
      relatedDetails: relatedData,
    });
  }

  patch(
    ctx: ApiContext,
    appId: string,
    id: string,
    data: Data,
  ): Promise<Resource> {
    if (!id) {
      return this.patchNew(ctx, appId, data);
    }

    return this.patchEdit(ctx, appId, id, data);
  }

  patchEdit(
    ctx: ApiContext,
    appId: string,
    id: string,
    data: Data,
  ): Promise<Resource> {
    return this.patchJson(ctx, '/app/' + appId + '/' + id + '/edit', {
      details: data,
    });
  }

  patchNew(ctx: ApiContext, appId: string, data: Data): Promise<Resource> {
    return this.patchJson(ctx, '/app/' + appId + '/new', {
      details: data,
    });
  }

  remove(ctx: ApiContext, appId: string, id: string) {
    return this.deleteJson(ctx, '/app/' + appId + '/' + id);
  }

  newAppRequest(ctx: ApiContext, appId: string, data?: Data) {
    return this.getJson(ctx, `/app/${appId}/request/new`, data || {});
  }

  appRequest(ctx: ApiContext, appId: string, data?: Data) {
    return this.postJson(ctx, `/app/${appId}/request`, data || {});
  }

  newRequest(ctx: ApiContext, appId: string, id: string, data?: Data) {
    return this.getJson(ctx, `/app/${appId}/${id}/request/new`, data || {});
  }

  request(ctx: ApiContext, appId: string, id: string, data?: Data) {
    return this.postJson(ctx, `/app/${appId}/${id}/request`, data || {});
  }

  appGateway(ctx: ApiContext, appId: string, data?: Data) {
    return this.postJson(ctx, `/app/${appId}/gateway`, data || {});
  }

  resetAcl(ctx: ApiContext, appId: string) {
    return this.postJson(ctx, `/app/${appId}/resetacl`, {});
  }

  makeParams(query?: Query): string {
    return makeParams(query);
  }

  getJson = async (ctx: ApiContext, path: string, query?: Query) => {
    const params = this.makeParams(query);
    const headers = this.makeHeaders();

    const response = await fetch(this.url(path + params), {
      method: 'GET',
      headers,
      mode: 'cors',
      signal: ctx.signal,
    });

    await this.check(response);
    this.updateAccessToken(response);

    try {
      return await response.json();
    } catch {
      throw new ApiError(response, null);
    }
  };

  postJson = async (
    ctx: ApiContext,
    path: string,
    data: Data,
    query?: Query,
  ) => {
    return await this.sendJson(ctx, 'POST', path, data, query);
  };

  putJson = async (
    ctx: ApiContext,
    path: string,
    data: Data,
    query?: Query,
  ) => {
    return await this.sendJson(ctx, 'PUT', path, data, query);
  };

  patchJson = async (
    ctx: ApiContext,
    path: string,
    data: Data,
    query?: Query,
  ) => {
    return await this.sendJson(ctx, 'PATCH', path, data, query);
  };

  deleteJson = async (
    ctx: ApiContext,
    path: string,
    data?: Data,
    query?: Query,
  ) => {
    return await this.sendJson(ctx, 'DELETE', path, data, query);
  };

  sendJson = async (
    ctx: ApiContext,
    method: string,
    path: string,
    data?: Data,
    query?: Query,
  ) => {
    const params = this.makeParams(query);
    const headers = this.makeHeaders();

    const response = await fetch(this.url(path + params), {
      method,
      headers,
      mode: 'cors',
      body: JSON.stringify(data),
      signal: ctx.signal,
    });

    await this.check(response);
    this.updateAccessToken(response);

    // If x-download is specified in the response header, download the file.
    if (response.headers.get('X-Download')) {
      downloadByPost(response.headers.get('X-Download')!, {});
    }

    return await this.jsonOrNull(response);
  };

  postFormData = async (
    ctx: ApiContext,
    path: string,
    data: Data,
    files: FormFiles,
    query?: Query,
  ) => {
    return await this.sendFormData(ctx, 'POST', path, data, files, query);
  };

  putFormData = async (
    ctx: ApiContext,
    path: string,
    data: Data,
    files: FormFiles,
    query?: Query,
  ) => {
    return await this.sendFormData(ctx, 'PUT', path, data, files, query);
  };

  sendFormData = async (
    ctx: ApiContext,
    method: string,
    path: string,
    data?: Data,
    allFiles?: FormFiles,
    query?: Query,
  ) => {
    const params = this.makeParams(query);
    const headers = this.makeHeaders(true);

    const body = new FormData();
    body.append('json', JSON.stringify(data));

    if (allFiles) {
      Object.keys(allFiles).forEach((fieldID) => {
        const files = allFiles[fieldID];

        files.forEach((file) => {
          if (file) {
            body.append(fieldID, file);
          }
        });
      });
    }

    const response = await fetch(this.url(path + params), {
      method,
      headers,
      mode: 'cors',
      body: body,
      signal: ctx.signal,
    });

    await this.check(response);
    this.updateAccessToken(response);
    return await this.jsonOrNull(response);
  };

  makeHeaders(notSpecifyContentType: boolean = false) {
    const headers: HeadersInit = {
      Accept: 'application/json',
      [NAME_TIME_ZONE]: getTimeZone(),
    };

    if (!notSpecifyContentType) {
      headers['Content-Type'] = 'application/json';
    }

    if (this.accessToken) {
      headers['Authorization'] = 'Bearer ' + this.accessToken;
    }

    return headers;
  }

  url(path: string) {
    return this.baseUrl + path;
  }

  wsUrl(path: string) {
    return this.baseWsUrl + path;
  }

  check = async (response: Response) => {
    if (response.status === 401) {
      this.clear();
      window.location.reload();
      throw new ApiError(response, null);
    }

    if (response.status >= 200 && response.status < 300) {
      return;
    }

    let json;

    try {
      json = await response.json();
    } catch (e) {
      throw new ApiError(response, null);
    }

    throw new ApiError(response, json);
  };

  updateAccessToken(response: Response) {
    const token = response.headers.get('token');

    if (token) {
      this.accessToken = token;
      this.storage.setItem('access_token', token);
    }
  }

  jsonOrNull = async (response: Response) => {
    try {
      return await response.json();
    } catch {
      return null;
    }
  };

  newWebSocket = (path: string, query?: Query): WebSocket => {
    const params = this.makeParams(query);
    const tz = getTimeZoneQuery();
    const url = this.wsUrl(path) + concatParams(params, tz);
    return new WebSocket(url);
  };
}

function buildWsURL(baseUrl: string): string {
  if (process.env.NODE_ENV === 'development') {
    return 'ws://localhost:8080' + baseUrl;
  }

  if (baseUrl.match(/^http/)) {
    return baseUrl.replace(/^http/, 'ws');
  }

  return window.location.origin.replace(/^http/, 'ws') + baseUrl;
}

function concatParams(a: string, b: string) {
  if (a && b) {
    return a + '&' + b;
  }

  if (a) {
    return a;
  }

  if (b) {
    return '?' + b;
  }

  return '';
}

function buildData(data?: Data | URLSearchParams | string): Data {
  if (!data) {
    return {};
  }

  if (typeof data === 'string' || data instanceof String) {
    return convertToData(new URLSearchParams(data as string));
  }

  if (data instanceof URLSearchParams) {
    return convertToData(data as URLSearchParams);
  }

  return data as Data;
}

function convertToData(params: URLSearchParams): Data {
  const data: Data = {};

  for (let pair of params.entries()) {
    const [key, value] = pair;

    if (!data[key]) {
      data[key] = [];
    }

    data[key].push(value);
  }

  return data;
}

export function useApiContext(): ApiContext {
  const ctx = useMemo<ApiContext>(() => {
    return api.newContext();
  }, []);

  useEffect(() => {
    return () => {
      ctx.abort();
    };
  }, [ctx]);

  return ctx;
}

export function makeParams(query?: Query): string {
  if (!query) {
    return '';
  }

  if (typeof query === 'string') {
    return query;
  }

  if (query instanceof URLSearchParams) {
    return '?' + query.toString();
  }

  const enc = (key: string, val: string) => {
    const encKey = encodeURIComponent(key);
    const encVal = encodeURIComponent(val);
    return `${encKey}=${encVal}`;
  };

  if (Object.keys(query).length === 0) {
    return '';
  }

  const params = Object.keys(query)
    .map((key) => {
      if (Array.isArray(query[key])) {
        return query[key].map((val: string) => enc(key, val)).join('&');
      }

      return enc(key, query[key]);
    })
    .join('&');

  return `?${params}`;
}

export function buildConfigUrl(schemaId: string): string {
  return `/app/${schemaId}/config`;
}

// process.env.REACT_APP_CONTEXT_PATH is replaced by build script
const api = new Api(
  process.env.REACT_APP_CONTEXT_PATH || 'http://localhost:8080/api/v1',
);

export {api};
