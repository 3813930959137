import {DefaultButton} from '@fluentui/react';
import {Location} from 'history';
import React, {RefObject, useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {api, ApiContext} from '../api';
import {ApiError} from '../ApiError';
import {Dialog} from '../common/Dialog';
import {ErrorMsg} from '../common/ErrorMsg';
import {newDummyNav} from '../common/Nav';
import {PrimaryHeader} from '../common/PrimaryHeader';
import {extractSections} from '../common/Schema';
import {Spinner} from '../common/Spinner';
import {Toolbar} from '../common/Toolbar';
import {selectComponent} from '../components/Components';
import {ItemDetails, ItemDetailsProps} from '../components/ItemDetails';
import {ItemForm} from '../components/ItemForm';
import {NavContext, NavProps} from '../context';
import {Actions} from '../types/Action';
import {Resource} from '../types/Resource';
import {ToolButton} from '../types/ToolButton';

type URLMatch = {
  appId: string;
  resourceId: string;
};

type Props = URLMatch & {
  location: Location;
  setNav: (nav: NavProps) => void;
};

type Status =
  | 'loading'
  | 'loaded'
  | 'editing'
  | 'requesting'
  | 'requested'
  | 'fatal';

type State = {
  status: Status;
  errors: {[key: string]: string[]};
  resourceId: string;
  resource?: Resource;
  newResource?: Resource;
  message?: string;
};

type Values = {
  [key: string]: any;
};

export function ConfigScreen(): JSX.Element | null {
  const params = useParams<URLMatch>();
  const location = useLocation();
  const {setNav} = useContext(NavContext);

  return (
    <ConfigScreenComponent {...params} location={location} setNav={setNav} />
  );
}

class ConfigScreenComponent extends React.Component<Props, State> {
  readonly ctx: ApiContext;
  readonly appId: string;
  readonly requestDialog: RefObject<Dialog>;

  constructor(props: Props) {
    super(props);

    this.state = {
      status: 'loading',
      resourceId: 'config',
      errors: {},
    };

    this.appId = props.appId;
    this.ctx = api.newContext();
    this.requestDialog = React.createRef();
  }

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.ctx.abort();
  }

  load = async () => {
    try {
      const params = new URLSearchParams(this.props.location.search);
      const resource = await api.loadConfig(this.ctx, this.appId, params);

      this.setState({
        resource,
        status: 'loaded',
      });
      this.props.setNav(resource.nav);
    } catch (e) {
      if (e instanceof ApiError) {
        this.setState({
          status: 'fatal',
          errors: {_: e.getMessages()},
        });
        this.props.setNav(newDummyNav());
      }
    }
  };

  onEdit = () => {
    this.setState({
      status: 'editing',
    });
  };

  onResetAcl = async () => {
    try {
      await this.setState({
        status: 'requesting',
      });

      this.requestDialog.current!.showDialog();

      const resp = await api.resetAcl(this.ctx, this.state.resource!.schema.id);

      this.setState({
        status: 'requested',
        message: resp.message,
      });
    } catch (e) {
      this.handleError(e);
      this.setState({
        status: 'requested',
      });
    }
  };

  onSaveEdit = async (values: Values) => {
    // config does not currently support attachments.
    const resource = await api.update(
      this.ctx,
      this.appId,
      this.state.resourceId,
      values,
    );

    this.setState({
      status: 'loaded',
      resource,
    });
  };

  buildButtons(): ToolButton[] {
    const buttons = getButtons(this.state.resource!);

    const mappings: {[key: string]: any} = {
      edit: this.onEdit,
      request: this.onResetAcl, //TODO Do not specify ResetAcl directly. Use another button type?
    };

    buttons.forEach((button) => {
      button.onClick = mappings[button.type];
    });

    return buttons;
  }

  buildActions(): Actions {
    //TODO
    return {};
  }

  handleError(e: any) {
    if (e instanceof ApiError) {
      this.setState({
        errors: e.getMessageMap(),
      });
    }
  }

  renderDetails() {
    if (this.state.status === 'fatal') {
      return (
        <ItemContainer>
          <ItemHeader>
            <PrimaryHeader
              iconName={'Error'}
              type={'error'}
              text={this.state.errors['_'].join(' ')}
            />
          </ItemHeader>
          <ItemBody />
        </ItemContainer>
      );
    }

    if (!this.state.resource) {
      return null;
    }

    const props = selectComponent<ItemDetailsProps>(
      this.state.resource.schema.screen.components,
      'item',
    );

    if (!props) {
      return null;
    }

    if (this.state.status === 'editing') {
      return (
        <ItemContainer>
          <ItemHeader>
            <PrimaryHeader iconName={'Edit'} text={'編集'} />
          </ItemHeader>
          <ItemBody>
            <ItemForm
              ctx={this.ctx}
              resource={this.state.resource}
              sections={extractSections(this.state.resource!.schema)}
              onCancel={() => {
                this.setState({status: 'loaded', errors: {}});
              }}
              onSave={this.onSaveEdit}
              actions={this.buildActions()}
              fieldSeparator={props.fieldSeparator}
            />
          </ItemBody>
        </ItemContainer>
      );
    }

    return (
      <>
        <ItemContainer>
          <ItemHeader>
            <Toolbar buttons={this.buildButtons()} />
          </ItemHeader>
          <ItemBody>
            <ItemDetails
              resource={this.state.resource}
              sections={extractSections(this.state.resource.schema)}
              actions={this.buildActions()}
              fieldSeparator={props.fieldSeparator}
            />
          </ItemBody>
        </ItemContainer>
        {this.renderRequestDialog()}
      </>
    );
  }

  renderRequestDialog() {
    return (
      <Dialog ref={this.requestDialog} modal={true}>
        {this.renderRequestDialogContent()}
      </Dialog>
    );
  }

  renderRequestDialogContent() {
    if (this.state.status === 'requesting') {
      return <Spinner />;
    }

    return (
      <RequestDialogContents>
        <ErrorMsg messages={this.state.errors['_']} />
        {this.state.message}
        <DefaultButton
          onClick={() => {
            this.requestDialog.current!.closeDialog();
          }}
          text={'閉じる'}
          styles={{root: {marginTop: '1rem'}}}
        />
      </RequestDialogContents>
    );
  }

  render() {
    if (this.state.status === 'loading') {
      return <Spinner />;
    }

    return <Container>{this.renderDetails()}</Container>;
  }
}

function getButtons(res: Resource): ToolButton[] {
  const props = selectComponent<ItemDetailsProps>(
    res.schema.screen.components,
    'item',
  );

  if (!props) {
    return [];
  }

  return props.buttons;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemHeader = styled.div``;

const ItemBody = styled.div`
  height: 100%;
  overflow: auto;
`;

const RequestDialogContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`;
