import {
  PARAM_KEY_END_DATE,
  PARAM_KEY_ORDER,
  PARAM_KEY_PAGE,
  PARAM_KEY_SIZE,
  PARAM_KEY_SORT,
  PARAM_KEY_START_DATE,
} from '../consts';
import {Params} from '../types/Params';
import {Schema} from '../types/Schema';
import {Sorter} from '../types/Sorter';
import {array} from '../util';

export function getParamValue(params: Params, key: string): string {
  const vs = params[key];

  if (!vs) {
    return '';
  }

  if (Array.isArray(vs)) {
    if (vs.length === 0) {
      return '';
    }

    return vs[0];
  }

  return vs;
}

export function excludeCommonParams(params: Params): Params {
  const newParams: Params = {};

  for (let key of Object.keys(params)) {
    if (!isCommonKey(key)) {
      newParams[key] = copy(params[key]);
    }
  }

  return newParams;
}

function isCommonKey(key: string): boolean {
  return commonKeys.has(key);
}

const commonKeys = new Set([
  PARAM_KEY_PAGE,
  PARAM_KEY_SIZE,
  PARAM_KEY_SORT,
  PARAM_KEY_ORDER,
  PARAM_KEY_START_DATE,
  PARAM_KEY_END_DATE,
]);

function copy(v: string | string[]): string | string[] {
  if (Array.isArray(v)) {
    return [...v];
  }

  return v;
}

export function setSortParams(params: URLSearchParams, sorter: Sorter) {
  params.set(PARAM_KEY_SORT, sorter.id);
  params.set(PARAM_KEY_ORDER, sorter.defaultOrder);
}

export function setDefaultParams(params: URLSearchParams, schema: Schema) {
  for (let f of schema.fields) {
    if (f.searchDefaultValue) {
      if (Array.isArray(f.searchDefaultValue)) {
        for (let v of f.searchDefaultValue) {
          params.append(f.id, v);
        }
      } else {
        params.set(f.id, f.searchDefaultValue);
      }
    }
  }

  for (let [key, value] of Object.entries(schema.screen.defaultParams || {})) {
    for (let v of array(value)) {
      params.append(key, v);
    }
  }
}

export function excludeKnownParams(
  params: Params,
  known: URLSearchParams,
): Params {
  const newParams: Params = {};

  for (let key of Object.keys(params)) {
    if (!known.has(key)) {
      newParams[key] = copy(params[key]);
    }
  }

  return newParams;
}
