import {Location} from 'history';
import React, {useContext} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {api, ApiContext, useApiContext} from '../api';
import {ApiError} from '../ApiError';
import {buildCreateRefParams} from '../common/actions/CreateRef';
import {DeleteDialog} from '../common/dialogs/DeleteDialog';
import {RequestDialog} from '../common/dialogs/RequestDialog';
import {newDummyNav} from '../common/Nav';
import {PrimaryHeader} from '../common/PrimaryHeader';
import {extractSections} from '../common/Schema';
import {Spinner} from '../common/Spinner';
import {Toolbar} from '../common/Toolbar';
import {UIActions} from '../common/uiactions/UIActions';
import {selectComponent} from '../components/Components';
import {Item, Mode} from '../components/Item';
import {ItemDetails, ItemDetailsProps} from '../components/ItemDetails';
import {ItemForm} from '../components/ItemForm';
import {NavContext, SetNav} from '../context';
import {GridLayout} from '../layout/GridLayout';
import {Actions} from '../types/Action';
import {FormFiles} from '../types/Form';
import {Resource} from '../types/Resource';
import {Component, Schema} from '../types/Schema';
import {ToolButton} from '../types/ToolButton';

export type ItemScreenURLMatch = {
  appId: string;
  resourceId?: string;
  proxyKey?: string;
  mode?: Mode;
};

type Props = ItemScreenURLMatch & {
  location: Location;
  setNav: SetNav;
};

type Status =
  | 'loading'
  | 'loaded'
  | 'creating'
  | 'creating_ref'
  | 'editing'
  | 'copying'
  | 'deleting'
  | 'deleted'
  | 'requesting_item'
  | 'requested'
  | 'fatal';

type State = {
  status: Status;
  errors: {[key: string]: string[]};
  schema?: Schema;
  resourceId: string;
  resource?: Resource;
  newResource?: Resource;
  button?: ToolButton;
  message: string;
};

type Values = {
  [key: string]: any;
};

export class ItemScreenComponent extends React.Component<Props, State> {
  readonly ctx: ApiContext;
  readonly appId: string;
  private readonly uiActions: UIActions;

  constructor(props: Props) {
    super(props);

    const id = props.resourceId || 'proxy/' + props.proxyKey;

    this.state = {
      status: 'loading',
      resourceId: id,
      errors: {},
      message: '',
    };

    this.appId = props.appId;
    this.ctx = api.newContext();
    this.uiActions = this.buildUIActions();
  }

  componentDidMount() {
    this.loadSchema();
    this.load();
  }

  componentWillUnmount() {
    this.ctx.abort();
  }

  getOptionalParams() {
    return {};
  }

  getScreen() {
    if (this.state.newResource && this.state.newResource.schema) {
      return this.state.newResource.schema.screen;
    }

    if (this.state.resource && this.state.resource.schema) {
      return this.state.resource.schema.screen;
    }

    if (this.state.schema) {
      return this.state.schema.screen;
    }

    return null;
  }

  loadSchema = async () => {
    try {
      const schema = await api.fetchSchema(this.ctx, this.appId, {
        _schema_for: 'item',
      });
      this.setState({schema});
    } catch (e) {}
  };

  load = async () => {
    try {
      let resource = await api.show(
        this.ctx,
        this.appId,
        this.state.resourceId,
        this.getOptionalParams(),
      );

      if (
        resource.details.schema_id &&
        resource.details.schema_id !== this.appId
      ) {
        resource = await api.show(
          this.ctx,
          resource.details.schema_id,
          this.state.resourceId,
        );
      }

      this.setState({
        resource,
        status: 'loaded',
      });

      this.setNav(resource);
    } catch (e) {
      if (e instanceof ApiError) {
        this.setState({
          status: 'fatal',
          errors: {_: e.getMessages()},
        });
        this.props.setNav(newDummyNav());
      }
    }
  };

  setNav(r: Resource) {
    this.props.setNav(r.nav);
  }

  handleError(e: any) {
    if (e instanceof ApiError) {
      this.setState({
        errors: e.getMessageMap(),
      });
    }
  }

  onCopy = async () => {
    this.setState({
      status: 'loading',
    });

    const res = await api.copy(this.ctx, this.appId, this.state.resourceId);

    this.setState({
      newResource: res,
      status: 'copying',
    });
  };

  onCreate = async (button: ToolButton) => {
    this.setState({
      status: 'loading',
    });

    const appId = button.itemType || this.state.resource!.schema.id;
    const resource = await api.new_(this.ctx, appId);

    this.setState({
      newResource: resource,
      status: 'creating',
      button: button,
    });
  };

  onCreateRef = async (button: ToolButton) => {
    this.setState({
      status: 'loading',
    });

    const appId = button.itemType || this.state.resource!.schema.id;
    const params = buildCreateRefParams(this.state.resource!, button);
    const resource = await api.new_(this.ctx, appId, params);

    this.setState({
      newResource: resource,
      status: 'creating_ref',
      button: button,
    });
  };

  onEdit = async () => {
    this.setState({
      status: 'loading',
    });

    const res = await api.edit(this.ctx, this.appId, this.state.resourceId);

    this.setState({
      newResource: res,
      status: 'editing',
    });
  };

  onDelete = () => {
    this.setState({
      status: 'deleting',
    });
  };

  onDownload = async (button: ToolButton) => {
    if (button.path) {
      window.open(button.path);
      return;
    }

    window.open(`/app/${this.appId}/${this.state.resourceId}/download`);
  };

  onSaveCreate = async (
    values: Values,
    files: FormFiles,
    relatedValues: any,
  ) => {
    const resource = await api.create(
      this.ctx,
      this.state.newResource!.schema.id || this.appId,
      values,
      files,
      relatedValues,
    );

    this.setState({
      status: 'loaded',
      resource,
      resourceId: resource.id,
      errors: {},
    });
  };

  onSaveCreateRef = async (
    values: Values,
    files: FormFiles,
    relatedValues: any,
  ) => {
    await api.create(
      this.ctx,
      this.state.newResource!.schema.id || this.appId,
      values,
      files,
      relatedValues,
    );

    const reloaded = await api.show(
      this.ctx,
      this.appId,
      this.state.resourceId,
    );

    this.setState({
      status: 'loaded',
      resource: reloaded,
      errors: {},
    });
  };

  onSaveEdit = async (values: Values, files: FormFiles, relatedValues: any) => {
    const resource = await api.update(
      this.ctx,
      this.state.resource!.schema.id,
      this.state.resource!.id,
      values,
      files,
      relatedValues,
    );

    this.setState({
      status: 'loaded',
      resource,
      resourceId: resource.id,
      errors: {},
    });
  };

  onAfterDelete = async () => {
    try {
      this.setState({
        status: 'deleted',
        errors: {},
      });
      return true;
    } catch (e) {
      this.handleError(e);
      return false;
    }
  };

  onShow = async () => {
    window.open(
      `/app/${this.state.resource!.schema.id}/${this.state.resource!.id}`,
    );
  };

  onRequest = async (button: ToolButton) => {
    await this.setState({
      status: 'requesting_item',
      button,
    });
  };

  resetStatus = () => {
    if (this.state.status !== 'fatal' && this.state.status !== 'deleted') {
      this.setState({status: 'loaded'});
    }
  };

  loadItem = (schemaId: string, id: string) => {
    window.location.href = `/app/${schemaId}/${id}`;
  };

  buildActions(): Actions {
    return {
      request_immediately: async (args) => {
        const res = await api.request(
          this.ctx,
          args.schema.id,
          args.item.id,
          args.params,
        );

        this.uiActions.doAction(res);
      },
    };
  }

  buildUIActions(): UIActions {
    return new UIActions({
      load: this.loadItem,
    });
  }

  setTitle = () => {};

  renderItemHeader(buttons: ToolButton[], farButtons: ToolButton[]) {
    const mappings: {[key: string]: any} = {
      create_ref: this.onCreateRef,
      edit: this.onEdit,
      delete: this.onDelete,
      copy: this.onCopy,
      show: this.onShow,
      download: this.onDownload,
      request: this.onRequest,
    };

    buttons.forEach((button) => {
      button.onClick = mappings[button.type];
    });

    farButtons.forEach((button) => {
      button.onClick = mappings[button.type];
    });

    return (
      <ItemHeader>
        <Toolbar buttons={buttons} farButtons={farButtons} />
      </ItemHeader>
    );
  }

  renderForm(
    res: Resource,
    onSave: (
      values: Values,
      files: FormFiles,
      relatedValues: any,
    ) => Promise<void>,
    title: string,
    iconName: string,
  ) {
    const sections = extractSections(res.schema);

    const props = selectComponent<ItemDetailsProps>(
      res.schema.screen.components,
      'item',
    );

    if (!props) {
      return null;
    }

    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader iconName={iconName} text={title} />
        </ItemHeader>
        <ItemBody>
          <ItemForm
            ctx={this.ctx}
            resource={res}
            sections={sections}
            labelPosition={props.labelPosition}
            fieldSeparator={props.fieldSeparator}
            onCancel={() => {
              if (this.state.resource) {
                this.setState({status: 'loaded', errors: {}});
              } else if (window.opener) {
                window.close();
              } else {
                window.history.back();
              }
            }}
            onSave={onSave}
            actions={this.buildActions()}
          />
        </ItemBody>
      </ItemContainer>
    );
  }

  renderItem() {
    const {status, resource, newResource} = this.state;

    if (status === 'fatal') {
      return this.renderFatalError();
    }

    if (status === 'deleted') {
      return this.renderDeleted();
    }

    if (status === 'editing') {
      return this.renderForm(newResource!, this.onSaveEdit, '編集', 'Edit');
    }

    if (status === 'creating') {
      return this.renderForm(newResource!, this.onSaveCreate, '作成', 'Add');
    }

    if (status === 'creating_ref') {
      return this.renderForm(newResource!, this.onSaveCreateRef, '作成', 'Add');
    }

    if (status === 'copying') {
      return this.renderForm(newResource!, this.onSaveCreate, '作成', 'Add');
    }

    if (resource) {
      return this.renderDetails(resource);
    }

    return null;
  }

  renderFatalError() {
    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader
            iconName={'Error'}
            type={'error'}
            text={this.state.errors['_'].join(' ')}
          />
        </ItemHeader>
        <ItemBody />
      </ItemContainer>
    );
  }

  renderDetails(res: Resource) {
    const props = selectComponent<ItemDetailsProps>(
      res.schema.screen.components,
      'item',
    );

    if (!props) {
      return null;
    }

    const sections = extractSections(res.schema);

    // show, delete
    return (
      <>
        <ItemContainer>
          {this.renderItemHeader(props.buttons, props.farButtons)}
          <ItemBody>
            <ItemDetails
              resource={res}
              sections={sections}
              labelPosition={props.labelPosition}
              fieldSeparator={props.fieldSeparator}
              actions={this.buildActions()}
            />
          </ItemBody>
        </ItemContainer>
      </>
    );
  }

  renderDeleted() {
    return (
      <ItemContainer>
        <ItemHeader>
          <PrimaryHeader iconName={'Delete'} text={'削除済み'} />
        </ItemHeader>
        <ItemBody>
          <MessageContainer>
            <div>削除しました。</div>
            {/*<LinkButton href={`/app/${this.appId}`}>一覧へ移動する</LinkButton>*/}
          </MessageContainer>
        </ItemBody>
      </ItemContainer>
    );
  }

  renderDeleteDialog() {
    if (!this.state.resource) {
      return null;
    }

    return (
      <DeleteDialog
        ctx={this.ctx}
        shown={this.state.status === 'deleting'}
        appId={this.state.resource.schema.id}
        resId={this.state.resource.id}
        onAfter={this.onAfterDelete}
        onClose={() => {
          // avoid overriding 'deleted' status
          //TODO fix the way to avoid this
          window.setTimeout(this.resetStatus, 33);
        }}
      />
    );
  }

  renderRequestDialog() {
    const target = this.state.resource;

    if (!target) {
      return null;
    }

    return (
      <RequestDialog
        ctx={this.ctx}
        shown={this.state.status === 'requesting_item'}
        target={{
          schemaId: target.details.schema_id,
          resId: target.details.id,
        }}
        params={this.state.button?.params}
        onAfterRequest={() => {}}
        onClose={(resp) => {
          this.uiActions.doAction(resp);
          this.resetStatus();
        }}
        actions={this.buildActions()}
      />
    );
  }

  renderComponents(components: {[key: string]: any}) {
    const result: {[key: string]: any} = {};

    Object.keys(components).forEach((key) => {
      result[key] = this.renderComponent(components[key]);
    });

    return result;
  }

  renderComponent(component: Component) {
    switch (component.type) {
      case 'item':
        return this.renderItem();
      default:
        return null;
    }
  }

  render() {
    if (this.state.status === 'loading') {
      return <Spinner />;
    }

    const screen = this.getScreen();

    if (!screen) {
      return null;
    }

    return (
      <Container>
        <GridLayout
          areas={screen.areas}
          columns={screen.columns}
          rows={screen.rows}
          components={this.renderComponents(screen.components)}
          styles={screen.styles}
        />
        {this.renderDeleteDialog()}
        {this.renderRequestDialog()}
      </Container>
    );
  }
}

export function ItemScreen(): JSX.Element | null {
  const params = useParams<ItemScreenURLMatch>();
  const {setNav} = useContext(NavContext);
  const location = useLocation();

  //TODO remove later
  const dev = new URLSearchParams(location.search).get('dev') === '1';

  if (dev) {
    return <DevItem {...params} />;
  }

  return (
    <ItemScreenComponent {...params} location={location} setNav={setNav} />
  );
}

type DevItemProps = ItemScreenURLMatch & {};

function DevItem(props: DevItemProps): JSX.Element | null {
  const ctx = useApiContext();
  const appId = props.appId;
  const id = props.resourceId;
  const mode = props.mode || 'show';
  return <Item appId={appId} id={id} ctx={ctx} mode={mode} />;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemHeader = styled.div``;

const ItemBody = styled.div`
  height: 100%;
  overflow: auto;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
